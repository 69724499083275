export default {
	companyRegistration: "公司认证",
	personalRegistration: "个人认证",
	totalRegistration: "注册总数量",
	completing: "进行中",
	unCompleted: "未完成",
	currentMonth: "当月",
	last30Days: "过去30天",
	other: "其他",
	customerGrowth: "公司认证趋势",

	staffAllPassedNum: "通过安装认证数量",
	zeroPassedNum: "认证通过率",

	passedNum: "通过认证数量",
	pendingCertificationNum: "认证通过率",
	corporateCertificationTrends: "公司认证趋势",
	certificationCourse: "认证课程",
	CertificationCourseCompletionTrends: "课程认证完成趋势",

	companyTooltips: {
		text1: "注册总数量：公司注册总数量",
		text2: "认证通过数量：公司名下至少一个账号通过安装认证的公司数量",
		text3: "认证通过率：通过安装认证公司数量 /  注册总数量",
		text4: "通过安装/销售/设计认证数量：公司名下有一个及以上完成对应认证课程的公司数量"
	},
	personTooltips: {
		text1: "注册总数量：公司下员工注册总数量",
		text2: "通过认证数量：通过至少一门认证课程的账号数量",
		text3: "认证通过率：通过至少一门认证账号数量 /  注册总数量",
		text4: "通过安装/销售/设计认证数量：通过对应认证课程的账号数量"
	},

	installerPassedNum: "安装认证数量",
	salesPassedNum: "销售认证数量",
	designerPassedNum: "设计认证数量",

	installerPassedAccountNum: "安装认证账号数量",
	salesPassedAccountNum: "销售认证账号数量",
	designerPassedAccountNum: "设计认证账号数量"
};
