export default {
	title: "反馈",
	username: "用户名",
	companyName: "公司名称",
	subject: "主题",
	email: "邮箱",
	message: "消息",
	account: "账户",
	submitTime: "提交时间",
	submitCountry: "提交国家"
};
