const trainRoutes: Menu.MenuOptions[] = [
	{
		path: "/train",
		name: "train",
		meta: {
			icon: "list",
			title: "培训中心",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: true,
			isKeepAlive: true
		},
		children: [
			{
				path: "/train/dashboard",
				name: "train-dashboard",
				meta: {
					icon: "dashboard",
					title: "Dashboard",
					enTitle: "Train Dashboard",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				component: "/train/dashboard/index"
			},
			{
				path: "/train/course",
				name: "course",
				meta: {
					icon: "course",
					title: "课程管理",
					enTitle: "Course Management",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/train/course/list",
						name: "course-list",
						component: "/train/course/list",
						meta: {
							icon: "list",
							title: "课程",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/course/details",
						name: "course-details",
						component: "/train/course/courseDetails",
						meta: {
							icon: "",
							title: "章节详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/course/videos",
						name: "course-videos",
						redirect: "/train/course/videos/list",
						meta: {
							icon: "video",
							title: "视频库",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/course/videos/list",
								name: "course-videos-list",
								component: "/train/course/videos/list",
								meta: {
									icon: "",
									title: "列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/course/videos/details",
								name: "course-videos-details",
								component: "/train/course/videos/details",
								meta: {
									icon: "",
									title: "视频详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					},
					{
						path: "/train/course/examination",
						name: "course-examination",
						redirect: "/train/course/examination/list",
						meta: {
							icon: "mark",
							title: "试题库",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/course/examination/list",
								name: "course-examination-list",
								component: "/train/course/examination/list",
								meta: {
									icon: "",
									title: "列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/course/examination/details",
								name: "course-examination-details",
								component: "/train/course/examination/details",
								meta: {
									icon: "",
									title: "详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					},
					{
						path: "/train/course/version",
						name: "course-version",
						redirect: "/train/course/version/list",
						meta: {
							icon: "",
							title: "版本管理",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/course/version/list",
								name: "course-version-list",
								component: "/train/course/version/list",
								meta: {
									icon: "",
									title: "版本列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/course/version/details",
								name: "course-version-detail",
								component: "/train/course/version/details",
								meta: {
									icon: "",
									title: "版本详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					}
				]
			},
			{
				path: "/train/content",
				name: "content",
				meta: {
					icon: "tag",
					title: "内容管理",
					enTitle: "content Management",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/train/content/document",
						name: "document",
						redirect: "/train/content/document/list",
						meta: {
							icon: "document",
							title: "文档管理",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/content/document/list",
								name: "document-list",
								component: "/train/content/document/list",
								meta: {
									icon: "",
									title: "文档列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/content/document/detail",
								name: "document-detail",
								component: "/train/content/document/detail",
								meta: {
									icon: "",
									title: "文档详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					},
					{
						path: "/train/content/media",
						name: "media",
						redirect: "/train/content/media/list",
						meta: {
							icon: "video",
							title: "媒体管理",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/content/media/list",
								name: "media-list",
								component: "/train/content/media/list",
								meta: {
									icon: "",
									title: "媒体列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/content/media/detail",
								name: "media-detail",
								component: "/train/content/media/detail",
								meta: {
									icon: "",
									title: "媒体详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					},
					{
						path: "/train/content/basic-configuration/index",
						name: "basic-configuration",
						component: "/train/content/basic-configuration/index",
						meta: {
							icon: "baseConfig",
							title: "Basic configuration",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/content/faq",
						name: "faq",
						redirect: "/train/content/faq/list",
						meta: {
							icon: "faq",
							title: "FAQ管理",
							enTitle: "",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						children: [
							{
								path: "/train/content/faq/list",
								name: "faq-list",
								component: "/train/content/faq/list",
								meta: {
									icon: "",
									title: "FAQ列表",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							},
							{
								path: "/train/content/faq/detail",
								name: "faq-detail",
								component: "/train/content/faq/detail",
								meta: {
									icon: "",
									title: "FAQ详情",
									enTitle: "",
									isLink: "",
									isHide: true,
									isFull: false,
									isAffix: true,
									isKeepAlive: true
								}
							}
						]
					}
				]
			},

			{
				path: "/train/notice",
				name: "notice",
				redirect: "/train/notice/list",
				meta: {
					icon: "notice",
					title: "消息管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/train/notice/list",
						name: "notice-list",
						component: "/train/notice/list",
						meta: {
							icon: "",
							title: "消息列表",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/notice/detail",
						name: "notice-detail",
						component: "/train/notice/detail",
						meta: {
							icon: "",
							title: "消息详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					}
				]
			},

			// 文档中心
			{
				path: "/train/document",
				name: "document-center",
				meta: {
					icon: "file",
					title: "文档中心",
					enTitle: "Document Center",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/train/document/management",
						name: "document-management",
						component: "/train/document/management/list",
						meta: {
							icon: "document",
							title: "资料包管理",
							enTitle: "Data package management",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/document/management/create",
						name: "document-create",
						component: "/train/document/management/create",
						meta: {
							icon: "document",
							title: "文档创建",
							enTitle: "Document Create",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/document/management/edit",
						name: "document-edit",
						component: "/train/document/management/create",
						meta: {
							icon: "document",
							title: "文档编辑",
							enTitle: "Document Edit",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					},
					{
						path: "/train/document/download-record",
						name: "download-record",
						component: "/train/document/download-record/index",
						meta: {
							icon: "download2",
							title: "下载记录",
							enTitle: "Download Record",
							isLink: "",
							isHide: false,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						}
					}
				]
			}
		]
	}
];

export default trainRoutes;
