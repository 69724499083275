import { ElTooltip } from "element-plus";
import type { Directive, DirectiveBinding } from "vue";
import { createVNode, render } from "vue";

const hook = (el: HTMLElement, binding: DirectiveBinding) => {
	const { value = "", arg = {} } = binding;
	const { limitLine = 1, placement = "top", effect = "light" } = arg as Record<string, any>;

	el.innerHTML = value;

	// 获取元素的计算样式
	const computedStyle = window.getComputedStyle(el);

	// 通过 lineHeight 属性获取行高值
	const lineHeight = computedStyle.getPropertyValue("line-height");

	const overflowHeight = el.clientHeight > limitLine * parseFloat(lineHeight);
	const overflowWidth = el.clientWidth < el.scrollWidth;
	const enableTooltip = overflowHeight || overflowWidth;
	if (enableTooltip) {
		const element = createVNode(
			ElTooltip,
			{
				placement,
				effect,
				content: value,
				"raw-content": true,
				"popper-class": "custom-tooltip"
			},
			{
				default: () =>
					createVNode(
						"div",
						{
							class: `line-clamp-${limitLine} overflow-hidden text-ellipsis`
						},
						value
					)
			}
		);

		el.innerHTML = "";

		const container = document.createElement("div");

		render(element, container);

		el.appendChild(container);
	} else {
		render(value, el);
	}
};

const directive: Directive = {
	mounted: hook,
	updated: hook
};

export default directive;
