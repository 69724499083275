export default {
	enterInput: "输入姓名、邮箱查找",
	addInstructor: "添加讲师",
	instructorName: "讲师姓名",
	email: "邮箱",
	qrCode: "二维码",
	type: "类型",
	internalInstructor: "内训讲师",
	externalInstructor: "外训讲师",
	duplicateMailbox: "邮箱重复",
	emailFormatError: "邮箱格式不正确",
	clickToDownload: "点击下载",
	trainingHistory: "培训记录",
	scanQRTime: "扫码时间",
	trainingType: "培训类型",
	trainingMethod: "培训方式",
	trainingObject: "培训对象",
	questionnaireName: "问卷名称",
	submitCount: "问卷提交人数",
	satisfaction: "满意度",
	trainMethod: "培训方式",
	surveyPreview: "调研概览",
	trainingTime: "培训时间",
	enterNameEmailTel: "请输入姓名、邮箱、电话、公司名称",
	startDate: "开始日期",
	endDate: "结束日期",
	USTime: "美国时间",
	submitName: "提交人姓名",
	submitEmail: "提交人邮箱",
	submitPhone: "提交人电话",
	submitPosition: " 提交人职位",
	submitCompanyName: "提交人公司名称",
	submitTime: "提交时间",
	question: "问题",
	singleChoice: "单选",
	multipleChoice: "多选",
	fillInField: "填写字段",
	surveyDetail: "调研详情",
	questionWeight: "本题权重得分",
	description: "描述",
	salesTraining: "销售培训",
	designTraining: "设计培训",
	serviceTraining: "服务培训",
	productTraining: "产品培训",
	questionnaireDescription: "问卷描述",
	questionnaireId: "问卷 ID",
	required: "必填",
	up: "向上",
	down: "向下",
	questionnaireNameRequired: "问卷名称必填",
	questionnaireDescriptionRequired: "问卷描述必填",
	trainingTypeRequired: "培训类型必选",
	titleRequired: "标题必填",
	itemIsRequired: "选项必填",
	field: "文本框必填",
	totalScoreTips:
		"当前合计共 <span class='hight-light'>{count}</span> 个问题， 最高得分 <span class='hight-light'>{totalScore}</span> 分",
	text: "文本",
	rating: "评分",
	addQuestion: "添加问题",
	addOption: "添加选项",
	questionTotalScore: "本题总分",
	itemScore: "选项分数",
	maxScoreRequired: "请输入本题最高可得分数",
	itemScoreRequired: "选项分数必填",
	selectCorrectAnswer: "请选择正确答案",
	ratingScoreValidate: "本题评分最低 5 分",
	positiveInteger: "请输入正整数",
	maxScoreValidate: "本题总分必填且为 0 或正整数",
	requiredPositiveInteger: "选项分数必填且为 0 或正整数",
	radioItemOverScore: "选项分数超过了设定最高分",
	maxScoreIs: "选项最高分不等于设定的问题最高分",
	itemOverScore: "选项分数总和超过了设定的最高分",
	allMaxScoreOver: "选项最高分总和超过 100",
	allMaxScoreLower: "选项最高分总和小于 100",
	itemMaxScoreNotMaxScore: "单个选项最高分不等于问题的最高分",
	morItemMaxScoreLowerMaxScore: "多个选项组合选项最高分未达到问题的最高分",
	morItemMaxScoreOverMaxScore: "多个选项组合选项最高分超过问题的最高分",
	deleteQuestionTips: "确定删除该问题?",
	atLeastOne: "至少添加一个问题",
	confirmCancel: "确定取消编辑当前页面吗?",
	noQRcode: "该讲师未生成二维码",
	enterInstructorName: "请输入讲师姓名",
	instructorNameLimit: "避免输入特殊字符",
	enterEmail: "请输入邮箱",
	typeRequired: "请选择讲师类型",
	editInstructor: "编辑讲师",
	totalScoreSubmitTips: "当前问卷问题总分合计不足100分，请检查问题设置",
	totalScoreOverSubmitTips: "当前问卷问题总分合计超出100分，请检查问题设置"
};
