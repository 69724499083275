export const questionnaireRoutes: Menu.MenuOptions[] = [
	{
		path: "/questionnaire",
		name: "questionnaire",
		meta: {
			icon: "questionnaire-survey",
			title: "问卷调查",
			enTitle: "Questionnaire Survey",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: true,
			isKeepAlive: true
		},
		children: [
			{
				path: "/questionnaire/instructor",
				name: "instructor-manage",
				redirect: "/questionnaire/instructor/list",
				meta: {
					icon: "teacher",
					title: "讲师管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/questionnaire/instructor/list",
						name: "instructor-list",
						meta: {
							icon: "",
							title: "讲师列表",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						component: "/questionnaire-survey/instructor-manage/list"
					},
					{
						path: "/questionnaire/instructor/detail",
						name: "instructor-detail",
						meta: {
							icon: "",
							title: "讲师详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: true
						},
						component: "/questionnaire-survey/instructor-manage/detail"
					},
					{
						path: "/questionnaire/instructor/train-history",
						name: "train-history-list",
						meta: {
							icon: "",
							title: "培训记录详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: false
						},
						component: "/questionnaire-survey/instructor-manage/train-history"
					}
				]
			},
			{
				path: "/questionnaire/manage",
				name: "questionnaire-manage",
				redirect: "/questionnaire/manage/list",
				meta: {
					icon: "questionnaire-manage",
					title: "问卷管理",
					enTitle: "Questionnaire Manage",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				},
				children: [
					{
						path: "/questionnaire/manage/list",
						name: "questionnaire-manage-list",
						meta: {
							icon: "",
							title: "列表",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: false
						},
						component: "/questionnaire-survey/questionnaire-manage/list"
					},
					{
						path: "/questionnaire/manage/create",
						name: "questionnaire-create",
						meta: {
							icon: "",
							title: "创建",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: false
						},
						component: "/questionnaire-survey/questionnaire-manage/detail"
					},
					{
						path: "/questionnaire/manage/edit",
						name: "questionnaire-edit",
						meta: {
							icon: "",
							title: "编辑",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: false
						},
						component: "/questionnaire-survey/questionnaire-manage/detail"
					},
					{
						path: "/questionnaire/manage/detail",
						name: "questionnaire-detail",
						meta: {
							icon: "",
							title: "详情",
							enTitle: "",
							isLink: "",
							isHide: true,
							isFull: false,
							isAffix: true,
							isKeepAlive: false
						},
						component: "/questionnaire-survey/questionnaire-manage/detail"
					}
				]
			}
		]
	}
];
