export default {
	permissionManage: "权限管理",
	matchLeads: "是否参与线索匹配",
	closeReason: "关闭原因",
	leadsPreferences: "线索偏好",
	systemAdmin: "系统管理员",
	seller: "销售",
	companyAccount: "公司账号",
	name: "姓名",
	phone: "电话",
	email: "邮箱",
	position: "职位",
	permission: "权限",
	assignPermission: "分配权限",
	account: "账号",
	createAccount: "创建账号",
	userName: "用户名",
	company: "公司名称",
	toBeReceived: "待接收",
	totalInProgress: "进行中",
	totalLeadsReceived: "已接收",
	totalLeadsToDeal: "赢单",
	totalLeadsFailed: "丢单",

	tooltips: {
		permission1: "打开参与线索匹配，即表示该安装商参与平台线索的匹配",
		permission2: "打开后系统管理员默认为安装商申请注册时的代理人账号",

		companyAccount2: "销售指该账号是否有权限接收管理员分配的线索，并查看相关数据",

		systemAdmin1: "打开参与线索匹配，即表示该安装商参与平台线索的匹配",
		systemAdmin2: "打开后系统管理员默认为安装商申请注册时的代理人账号",
		systemAdmin3: "支持更换线索管理员，线索管理员同时必须存在且只有一个",
		createSuccess: "账号已经创建成功，FleetView正在处理中，预计30分钟后处理完成。"
	},
	tips: {
		confirmClose: "确认关闭参与线索匹配吗?",
		openTips: "确认开启该安装商参与线索匹配吗?",
		closetips1: "关闭后平台将无法继续分配线索到安装商",
		closetips2: "关闭前已在途的线索无法继续在平台跟进",
		closetips3: "关闭后安装商名下所有账号将无法正常使用本平台",
		enterCloseReason: "请输入关闭原因",
		reasonlength: "长度不可超过 500 字符",
		usernameExists: "用户名已存在",
		emailExists: "邮箱已存在"
	}
};
