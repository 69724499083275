// ? 全局不动配置项 只做导出不做修改

// * 首页地址（默认）
export const HOME_URL: string = "/home";

// * 登录页地址（默认）
export const LOGIN_URL: string = "/login";

// * 自动登录地址（默认）
export const AUTO_LOGIN_URL: string = "/autologin";

// * 默认主题颜色
export const DEFAULT_PRIMARY: string = "#1E5EFF";

// * Tabs（白名单地址，不需要添加到 tabs 的路由地址）
export const TABS_WHITE_LIST: string[] = ["/403", "/404", "/500", LOGIN_URL];

// * 高德地图key
export const MAP_KEY: string = "";
