import { h, render, nextTick } from "vue";
import Toast from "./index.vue";

interface Options {
	type: "success" | "error" | "warning";
	title: string;
	close?: Function;
}

class ToastCreator {
	static TIME = 3000;

	options: Options;
	instance: any;
	container: HTMLDivElement;
	constructor(options: Options) {
		this.options = options;
		this.options.close = this.destroy.bind(this);
		this.container = document.createElement("div");
		this.container.className = "alert-container";
		this.instance = null;
		this.initInstance();
	}

	initInstance(): void {
		nextTick(() => {
			this.instance = h(h(Toast), this.options);
			render(this.instance, this.container);
			document.body.appendChild(this.container);

			this.container.classList.add("slide-in");

			setTimeout(() => {
				this.destroy();
			}, ToastCreator.TIME);
		});
	}

	destroy() {
		this.container.classList.remove("slide-in");
		this.container.classList.add("slide-out");

		// 等待退出动画完成后再从 DOM 中移除元素
		this.container.addEventListener("animationend", () => {
			document.body.removeChild(this.container);
			toastInstance = null;
		});
	}
}

let toastInstance: ToastCreator | null = null;

const FwhToast = {
	show(options: Options) {
		if (!toastInstance) {
			toastInstance = new ToastCreator(options);
		}

		return {
			hide: toastInstance.destroy.bind(toastInstance)
		};
	},
	hide() {
		toastInstance?.destroy();
	},
	success(title: string) {
		if (!toastInstance) {
			toastInstance = new ToastCreator({ title, type: "success" });
		}

		return {
			hide: toastInstance.destroy.bind(toastInstance)
		};
	},
	warning(title: string) {
		if (!toastInstance) {
			toastInstance = new ToastCreator({ title, type: "warning" });
		}

		return {
			hide: toastInstance.destroy.bind(toastInstance)
		};
	},
	error(title: string) {
		if (!toastInstance) {
			toastInstance = new ToastCreator({ title, type: "error" });
		}

		return {
			hide: toastInstance.destroy.bind(toastInstance)
		};
	}
};

export default FwhToast;
