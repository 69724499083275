/**
 * docs: https://cn.vitejs.dev/guide/features.html#glob-import-caveats
 */

const modules: any = import.meta.glob("./*.ts", { eager: true });
const modulesMap: Record<string, any> = {};

for (const path in modules) {
	const matcher: any = path.match(/^\.\/(.*)\.ts$/);
	const name = matcher[1];

	modulesMap[name] = modules[path].default;
}

export default {
	home: {
		welcome: "欢迎使用"
	},
	tabs: {
		more: "更多",
		refresh: "刷新",
		maximize: "最大化",
		closeCurrent: "关闭当前",
		closeOther: "关闭其它",
		closeAll: "关闭所有"
	},
	header: {
		componentSize: "组件大小",
		language: "国际化",
		theme: "全局主题",
		layoutConfig: "布局设置",
		primary: "primary",
		darkMode: "暗黑模式",
		greyMode: "灰色模式",
		weakMode: "色弱模式",
		fullScreen: "全屏",
		exitFullScreen: "退出全屏",
		personalData: "个人信息",
		personalCenter: "个人中心",
		changePassword: "修改密码",
		logout: "退出登录"
	},
	...modulesMap
};
