export default {
	title: "Feedback",
	username: "User Name",
	companyName: "Company Name",
	subject: "Subject",
	email: "Email",
	message: "Message",
	account: "Account",
	submitTime: "Submit Time",
	submitCountry: "Submit Country"
};
