import { defineStore } from "pinia";
import { AuthState } from "@/stores/interface";
import { getUserInfoApi } from "@/api/modules/login";
import { getShowMenuList, getAllBreadcrumbList, assembleRouter, flatMenuList } from "@/utils/util";
import piniaPersistConfig from "@/config/piniaPersist";
// import DynamicRouter from "@/assets/json/dynamicRouter.json";
import { routerMap } from "@/routers/modules/routerMap";
import { GlobalStore } from "../index";
// AuthStore
export const AuthStore = defineStore({
	id: "AuthState",
	state: (): AuthState => ({
		authButtonList: {},
		// menuList 作为动态路由，不会做持久化存储
		authMenuList: [],
		userInfo: {}
	}),
	getters: {
		// 按钮权限列表
		authButtonListGet: state => state.authButtonList,
		// 后端返回的菜单列表
		authMenuListGet: state => state.authMenuList,
		// 后端返回的菜单列表 ==> 左侧菜单栏渲染，需要去除 isHide == true
		showMenuListGet: state => getShowMenuList(state.authMenuList),
		// 面包屑导航列表
		breadcrumbListGet: state => getAllBreadcrumbList(state.authMenuList)
	},
	actions: {
		// getUserInfo
		async getUserInfo() {
			const { result } = await getUserInfoApi();
			const globalStore = GlobalStore();

			globalStore.setUserInfo(result);
			// 处理后端数据为路由表
			const flatMenu = flatMenuList(result.menulist);
			this.authMenuList = assembleRouter(flatMenu, routerMap);
			console.log("authMenuList", this.authMenuList);
		}
	},
	persist: piniaPersistConfig("AuthState", ["authButtonList"])
});
