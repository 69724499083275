import FwhToast from "@/components/Toast";

/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number): void => {
	switch (status) {
		case 400:
			FwhToast.error("The request failed! Please try again later");
			break;
		case 401:
			FwhToast.error("Login invalid! Please log in again");
			break;
		case 403:
			FwhToast.error("The current account has no permission to access!");
			break;
		case 404:
			FwhToast.error("The resource you are accessing does not exist!");
			break;
		case 405:
			FwhToast.error("The request method is wrong! Please try again later");
			break;
		case 408:
			FwhToast.error("The request timed out! Please try again later");
			break;
		case 500:
			FwhToast.error("Service exception!");
			break;
		case 502:
			FwhToast.error("Gateway error!");
			break;
		case 503:
			FwhToast.error("The service is unavailable!");
			break;
		case 504:
			FwhToast.error("Gateway timed out!");
			break;
		default:
			FwhToast.error("The request failed!");
	}
};
