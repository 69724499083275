export default {
	companyRegistration: "Number of companies",
	personalRegistration: "Number of accounts",
	totalRegistration: "Total number of registrations",
	completing: "in progress",
	unCompleted: "Not completed",
	currentMonth: "current month",
	last30Days: "Last 30 days",
	other: "other",
	customerGrowth: "Company Certification Trend",

	staffAllPassedNum: "Number of certified installation passes",
	zeroPassedNum: "Certification pass rate",

	passedNum: "Number of passed certifications",
	pendingCertificationNum: "Certification pass rate",
	corporateCertificationTrends: "Corporate Certification Trends",
	certificationCourse: "Certification Course",
	CertificationCourseCompletionTrends: "Course Certification Completion Trends",

	companyTooltips: {
		text1: "Total number of registrations: Total number of company registrations",
		text2:
			"Number of certifications passed: The number of companies with at least one account under the company's name that has passed the installation certification",
		text3:
			"Certification pass rate: number of companies that have passed installation certification / total number of registrations",
		text4:
			"Number of installation/sales/design certifications: the number of companies with one or more companies that have completed the corresponding certification courses"
	},
	personTooltips: {
		text1: "Total number of registrations: total number of registered employees under the company",
		text2: "Number of passed certifications: Number of accounts that have passed at least one certification course",
		text3:
			"Certification pass rate: number of accounts that have passed at least one certification / total number of registrations",
		text4:
			"Number of installation/sales/design certifications: Number of accounts that have passed the corresponding certification courses"
	},

	installerPassedNum: "Installation certification",
	salesPassedNum: "Sales certification",
	designerPassedNum: "Design certification",

	installerPassedAccountNum: "Installation certification",
	salesPassedAccountNum: "Sales Certification",
	designerPassedAccountNum: "Design Certification"
};
