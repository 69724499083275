/**
 * docs: https://cn.vitejs.dev/guide/features.html#glob-import-caveats
 */

const modules: any = import.meta.glob("./*.ts", { eager: true });
const modulesMap: Record<string, any> = {};

for (const path in modules) {
	const matcher: any = path.match(/^\.\/(.*)\.ts$/);
	const name = matcher[1];

	modulesMap[name] = modules[path].default;
}

export default {
	home: {
		welcome: "Welcome"
	},
	tabs: {
		more: "More",
		refresh: "Refresh",
		maximize: "Maximize",
		closeCurrent: "Close current",
		closeOther: "Close other",
		closeAll: "Close All"
	},
	header: {
		componentSize: "Component size",
		language: "Language",
		theme: "theme",
		layoutConfig: "Layout config",
		primary: "primary",
		darkMode: "Dark Mode",
		greyMode: "Grey mode",
		weakMode: "Weak mode",
		fullScreen: "Full Screen",
		exitFullScreen: "Exit Full Screen",
		personalData: "Personal Data",
		personalCenter: "Personal Center",
		changePassword: "Change Password",
		logout: "Logout"
	},
	...modulesMap
};
