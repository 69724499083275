import { createApp } from "vue";
import App from "./App.vue";
// reset style sheet
import "@/styles/reset.scss";
// CSS common style sheet
import "@/styles/common.scss";
// iconfont css
// import "@/assets/iconfont/iconfont.scss";
// import "@/assets/iconfont/iconfont.css";
// font css
import "@/assets/fonts/font.scss";
// element plus
import ElementPlus from "element-plus";
// element icons
import * as Icons from "@element-plus/icons-vue";
// element css
import "element-plus/dist/index.css";
// element dark(内置暗黑模式)
import "element-plus/theme-chalk/dark/css-vars.css";
// custom element dark(自定义暗黑模式)
import "@/styles/theme/element-dark.scss";
// custom element css
import "@/styles/element.scss";
// custom directives
import directives from "@/directives/index";
// vue Router
import router from "@/routers/index";
// vue i18n
import I18n from "@/locale/index";
// pinia store
import pinia from "@/stores/index";
// svg icons
import "virtual:svg-icons-register";
// errorHandler
// import errorHandler from "@/utils/errorHandler";

// import "tailwindcss/tailwind.css";
// import "@/assets/iconfont/iconfont.js";

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

const app = createApp(App);

// 暂时先在测试环境进行监控
// if (import.meta.env.MODE === "test") {
// 	Sentry.init({
// 		app,
// 		dsn: "https://aee37b19a8d24f4f8e582232efddf848@monitor.franklin-wh.com:10443/5",
// 		integrations: [
// 			new Integrations.BrowserTracing({
// 				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
// 				tracingOrigins: ["localhost", "crm-admin.franklin-wh.com", /^\//]
// 			})
// 		],
// 		// Set tracesSampleRate to 1.0 to capture 100%
// 		// of transactions for performance monitoring.
// 		// We recommend adjusting this value in production
// 		tracesSampleRate: 1.0
// 	});
// }

// app.config.errorHandler = errorHandler;

// 注册element Icons组件
Object.keys(Icons).forEach(key => {
	app.component(key, Icons[key as keyof typeof Icons]);
});

app.use(router).use(I18n).use(pinia).use(directives).use(ElementPlus).use(VueVirtualScroller).mount("#app");
