export default {
	matchingDistance: "安装商匹配地址相距",
	inKm: "km 以内",
	emailTitle: "邮件标题",
	acceptRole: "接收角色",
	templateName: "模板名称",
	templateType: "模板类型",
	templateCode: "模板简码",
	templateDescription: "模板说明",
	country: "国家",
	updateTime: "更新时间",
	templateStatus: "模板状态",
	emailContent: "邮件内容",
	homeowner: "房主",
	installer: "安装商",
	objectId: "对象ID",
	moduleName: "模块名称",
	operationType: "操作类型",
	name: "名称",
	ipAddress: "IP 地址",
	time: "时间",
	operationDetail: "操作详情",
	operatorInfo: "操作人信息",
	operationTime: "操作时间",
	operationRecord: "操作记录",
	module: "模块",
	operationContent: "操作内容",
	sendStatus: "发送状态",
	sendTime: "发送时间",
	nameAndEmail: "接收人姓名、邮箱",
	emailDetail: "邮件详情",
	waitingSend: "待发送",
	sending: "发送中",
	sendSucceed: "发送成功",
	sendFailed: "发送失败",
	sendStartTime: "发送开始时间",
	sendEndTime: "发送结束时间",
	custom: "自定义",
	system: "系统",

	rules: {
		kmRequired: "安装商匹配距离必填",

		websiteVersion: "请选择网站版本",
		country: "请选择所属国家",
		acceptRole: "请选择接收角色",
		templateName: "请输入模板名称",
		templateType: "请选择模板类型",
		templateCode: "请输入模板简码",
		templateDescription: "请输入模板说明",
		status: "请选择模板状态",
		emailTitle: "请输入邮件标题",
		emailContent: "请输入邮件内容",
		saveEmailCode: "已存在该邮件简码"
	},

	tips: {
		enableEmailTemplate: "是否确认启用该邮件模板?",
		disabledEmailTemplate: "是否确认禁用该邮件模板?"
	}
};
