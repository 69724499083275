export default {
	matchingDistance: "Installer matching distance within",
	inKm: "Within km",
	emailTitle: "Email Title",
	acceptRole: "Accept Role",
	templateName: "Template Name",
	templateType: "template Type",
	templateCode: "Template Code",
	templateDescription: "Template Description",
	country: "Country",
	updateTime: "Update Time",
	templateStatus: "Template Status",
	emailContent: "Email Content",
	homeowner: "Homeowner",
	installer: "Installer",
	objectId: "Object ID",
	moduleName: "Module Name",
	operationType: "Operation Type",
	name: "Name",
	ipAddress: "IP Address",
	time: "Time",
	operationDetail: "Operation Detail",
	operatorInfo: "Operator Info",
	operationTime: "Operation Time",
	operationRecord: "Operation Record",
	module: "Module",
	operationContent: "Operation Content",
	sendStatus: "Send Status",
	sendTime: "Send Time",
	nameAndEmail: "Recipient Name, Email",
	emailDetail: "Email Detail",
	waitingSend: "Waiting to Send",
	sending: "Sending",
	sendSucceed: "Send Succeeded",
	sendFailed: "Send Failed",
	sendStartTime: "Send Start Time",
	sendEndTime: "Send End Time",
	custom: "Custom",
	system: "System",

	rules: {
		kmRequired: "Installer matching distance is required",

		websiteVersion: "Please select a website version",
		country: "Please select a country",
		acceptRole: "Please select an accept role",
		templateName: "Please enter a template name",
		templateType: "Please select a template type",
		templateCode: "Please enter a template code",
		templateDescription: "Please enter a template description",
		status: "Please select a template status",
		emailTitle: "Please enter an email title",
		emailContent: "Please enter email content",
		saveEmailCode: "This email code already exists"
	},

	tips: {
		enableEmailTemplate: "Are you sure you want to enable this email template?",
		disabledEmailTemplate: "Are you sure you want to disable this email template?"
	}
};
