<template>
	<ElAlert
		ref="alertRef"
		:type="type"
		:title="title"
		center
		class="fwh-toast"
		:class="[isFixed && 'fixed', type]"
		@close="close"
	/>
</template>

<script setup lang="ts">
import { ElAlert } from "element-plus";
import { ref, computed } from "vue";
import { GlobalStore } from "@/stores";

const globalStore = GlobalStore();

const isFixed = computed(() => globalStore.themeConfig.isFixed);

interface Props {
	title: string;
	type: "success" | "error" | "warning";
	close?: Function;
}

const props = defineProps<Props>();

const alertRef = ref();

const close = () => {
	props.close && props.close();
};
</script>

<style scoped lang="scss">
.fwh-toast {
	position: absolute;
	top: $header-height;
	right: 0;
	left: $aside-collapse-width;
	z-index: 9998;
	display: flex;
	align-items: center;
	justify-content: center;
	width: calc(100% - $aside-collapse-width) !important;
	height: 50px;
	font-size: 16px;
	font-weight: 400;
	&.fixed {
		left: $aside-expand-width;
		width: calc(100% - $aside-expand-width) !important;
	}
	&.success {
		color: $toast-success-color;
		background-color: $toast-success-background;
	}
	&.warning {
		color: $toast-warning-color;
		background-color: $toast-warning-background;
	}
	&.error {
		color: $toast-error-color;
		background-color: $toast-error-background;
	}
	&.primary {
		color: $toast-primary-color;
		background-color: $toast-primary-background;
	}
	:deep(.el-alert__close-btn) {
		top: 19px !important;
		right: 18px !important;
	}
}
</style>
