const systemManageRoutes = [
	{
		path: "/system",
		name: "user-setting",
		meta: {
			icon: "setting",
			title: "系统设置",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: false,
			isKeepAlive: true
		},
		children: [
			{
				path: "/system/menu-list",
				name: "menu-list",
				component: "/system/menu/index",
				meta: {
					icon: "menu-list",
					title: "菜单管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/system/power-list",
				name: "power-list",
				component: "/system/power/index",
				meta: {
					icon: "permission-list",
					title: "权限管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/system/role-list",
				name: "role-list",
				component: "/system/role/index",
				meta: {
					icon: "role-list",
					title: "角色管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/system/admin-list",
				name: "admin-list",
				component: "/system/admin/index",
				meta: {
					icon: "account-list",
					title: "账号列表",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/system/organization-manage",
				name: "organization-manage",
				component: "/system/organization-manage/index",
				meta: {
					icon: "organization-manage",
					title: "组织管理",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			},
			{
				path: "/system/account-binding",
				name: "account-binding",
				component: "/system/account-binding/index",
				meta: {
					icon: "binding",
					title: "账号绑定",
					enTitle: "",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: false,
					isKeepAlive: true
				}
			}
		]
	},
	{
		path: "/user-center",
		name: "user-center",
		component: "/user-center/index",
		meta: {
			icon: "",
			title: "个人中心",
			enTitle: "",
			isLink: "",
			isHide: true,
			isFull: false,
			isAffix: false,
			isKeepAlive: true
		}
	}
];
export default systemManageRoutes;
