export default {
	versionManage: "版本管理",
	versionCode: "版本号",
	publishTime: "发布时间",
	updateTime: "更新时间",
	publishVersion: "发布版本",
	NO: "序号",
	courseName: "课程名称",
	courseObjective: "课程目标",
	trainMember: "培训对象",
	video: "视频",
	createTime: "创建时间",
	type: "类型",
	status: "状态",
	useStatus: "使用状态",
	operation: "操作",
	courseType: "课程类型",
	unitExam: "单元测试",
	comprehensiveExam: "综合测试",
	courseIntroduction: "课程简介",
	courseDescription: "课程描述",
	courseVideo: "课程视频",
	courseExam: "课程试题",
	examName: "课程名称",
	examList: "课程列表",
	websiteVersion: "网站版本",
	belongToCountry: "所属国家",
	country: "国家",
	state: "州",
	city: "城市",
	zipCode: "邮编",
	belongsToCourse: "所属课程",
	videoTitle: "视频名称",
	videoDuration: "视频时长",
	index: "序号",
	draft: "草稿",
	released: "已发布",
	unreleased: "未发布",
	effective: "已生效",
	notEffective: "未生效",
	offline: "已下线",
	edit: "编辑",
	cancel: "取消",
	save: "保存",
	uploadVideo: "上传视频",
	changeVideo: "更换视频",
	addVideo: "添加视频",
	content: "内容",
	certificationCourse: "认证课程",
	installer: "安装商",
	sales: "销售人员",
	designer: "设计师",
	needExamination: "是否考试",
	needNotification: "是否提醒",
	pending: "生效中...",
	createCustomCourse: "创建自定义课程",
	belongsToVersion: "所属版本",

	lessonType: "章节类型",
	lessonName: "章节名称",
	lessonDescription: "章节描述",
	lessonExamination: "章节试题",
	lessonVideo: "章节视频",
	randomQuantity: "随机组合试题数量",

	examination: {
		name: "试题名称",
		number: "题号",
		copy: "复制",
		addPicture: "添加图片",
		type: "试题类型",
		answerOption: "答案选项",
		addOption: "添加选项",
		correctAnswer: "正确答案",
		answerAnalysis: "答案解析",
		radio: "单选",
		checkbox: "多选",
		addExamination: "添加试题",
		used: "已使用",
		unUsed: "未使用",
		onlineStatus: "在线状态",
		online: "已上线",
		offline: "已下线",
		doOnline: "上线",
		doOffline: "下线"
	},

	version: {
		number: "版本号",
		addCourse: "添加课程",
		addLesson: "添加章节",
		createVersion: "创建版本",
		createVersionTips: "版本号保留一位小数，新增版本号必须大于已存在的版本号",
		language: "课程语言",
		english: "英语",
		spanish: "西语",
		addVersionLanguage: "增加新语言课程",
		createCourse: "创建课程",
		lessonName: "章节名称"
	},

	tips: {
		removeOption: "是否删除该选项",
		publishVersion: "即将发布 {version} 版本，是否继续？",
		cancelPublishVersion: "即将取消发布 {version} 版本，是否继续？",
		countLessThanTwo: "课程数量不足2条，无法进行排序",
		releaseDocument: "是否确定发布该文档?",
		cancelReleaseDocument: "是否确定取消发布该文档?",
		releaseMedia: "是否确定发布该媒体?",
		uploadVideoDescription1: "上传视频最大 500M",
		uploadVideoDescription2: "视频上传完成提交后，系统后台需要5-30分钟时间处理数据，处理完成后该视频才可以正常使用；",
		uploadVideoDescription3: "如为替换原视频操作，替换生效前查看视频为被替换视频",
		uploadingTips: "系统后台需要5-30分钟时间处理数据，处理完成后该视频才可以正常使用",
		confirmCopy: "是否确认复制该选项?",
		onlineVideo: "是否确定上线该视频?",
		offlineVideo: "是否确定下线该视频?",
		onlineExamination: "是否确定上线该试题?",
		offlineExamination: "是否确定下线该试题?"
	},

	document: {
		title: "标题",
		subtitle: "副标题",
		docTitle: "文档标题",
		description: "文档描述",
		uploadDocument: "上传文档",
		seoUrl: "SEO 地址",

		lang: "语言",
		selectLanguage: "选择语言",
		category: "分类",
		role: "角色"
	},

	media: {
		cover: "封面",
		title: "标题",
		videoTitle: "视频标题",
		summary: "副标题",
		tips: {
			release: "是否确定发布该媒体?",
			cancelRelease: "是否确定取消发布该媒体?"
		}
	},

	rules: {
		videoTitle: "请输入视频名称",
		chooseWebsiteVersion: "请选择网站版本",
		uploadVideo: "请上传视频",
		videoTitleLengthLimit: "视频名称长度限制在1-200之间",
		enterExamDescription: "请输入题目描述",
		chooseExamType: "请选择题目类型",
		enterAnswerOption: "请填写答案选项",
		chooseCorrectAnswer: "请选择正确答案",
		enterCompleteAnswerOption: "请填写完整答案选项",
		examinationVersion: "请输入发布版本",
		chooseCourseType: "请选择课程版本",
		chooseLessonType: "请选择章节版本",
		chooseCourseCatogory: "请选择课程类型",
		enterCourseName: "请输入课程名称",
		enterLessonName: "请输入章节名称",
		enterCourseIntroduction: "请输入课程简介",
		enterCourseDescription: "请输入课程描述",
		chooseLessonVideo: "请选择章节视频",
		chooseCourseExam: "请选择课程试题",
		chooseLessonExam: "请选择章节试题",
		enterRandomQuantity: "请输入随机试题数量",
		questionCountTips: "试题列表内试题数量必须大于等于随机组合试题数量",
		enterDocumentTitle: "请输入文档标题",
		enterDocumentTitleLimitTips: "最多输入 {length} 个字符",
		documentDescriptionLimitTips: "最多输入 {length} 个字符",
		uploadDocument: "请上传文档",
		enterVideoTitle: "请输入视频标题",
		chooseEffectiveType: "请选择生效类型",
		chooseEffectiveTime: "请选择生效时间",
		enterNoticeTitle: "请输入通知标题",
		enterfaqTitle: "请输入 FAQ 标题",
		enterNoticeContent: "请输入通知内容",
		enterFaqContent: "请输入 FAQ 内容",
		enterSeoUrl: "请输入 SEO url",
		trainMember: "请选择培训对象",
		courseType: "请选择课程类型",
		trainObject: "请选择培训对象",
		courseLanguage: "请选择课程语言",
		courseCover: "请上传课程封面图",
		chooseVersion: "请选择版本",
		chooseLanguage: "请选择语言",
		chooseCategory: "请选择分类",
		chooseUserRole: "请选择角色",
		examCountTips: "试题数量必须为正整数"
	},

	faq: {
		releaseTips: "是否确定发布此FAQ?",
		unReleaseTips: "是否确定取消发布此FAQ?"
	}
};
