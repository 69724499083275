export default {
	enterInput: "Enter name or email address to search",
	addInstructor: "Add Instructor",
	instructorName: "Instructor Name",
	email: "Email",
	qrCode: "QR Code",
	type: "Type",
	internalInstructor: "Internal Instructor",
	externalInstructor: "External Instructor",
	duplicateMailbox: "Duplicate Mail",
	emailFormatError: "Email format is incorrect.",
	clickToDownload: "Click to download",
	trainingHistory: "Training History",
	scanQRTime: "QR Code Scan Time",
	trainingType: "Type",
	trainingMethod: "Training Method",
	trainingObject: "Training Target",
	questionnaireName: "Questionnaire Name",
	submitCount: "Number Of Submissions",
	satisfaction: "Satisfaction Level",
	trainMethod: "Training Method",
	surveyPreview: "Survey Preview",
	trainingTime: "Training Time",
	enterNameEmailTel: "Enter name,email,phone number or company name",
	startDate: "Start Date",
	endDate: "End Date",
	USTime: "US Time",
	submitName: "Submitter's Name",
	submitEmail: "Submitter's Email",
	submitPhone: "Submitter's Phone",
	submitPosition: "Submitter's Position",
	submitCompanyName: "Submitter's Company Name",
	submitTime: "Submit Time",
	question: "Question",
	singleChoice: "Single Choice",
	multipleChoice: "Multiple Choice",
	fillInField: "Fill In Field",
	surveyDetail: "Survey Detail",
	questionWeight: "Weighted score for this question",
	description: "Description",
	salesTraining: "Sales Training",
	designTraining: "Design Training",
	serviceTraining: "Service Training",
	productTraining: "Product Training",
	questionnaireDescription: "Questionnaire Description",
	questionnaireId: "Questionnaire ID",
	required: "required",
	up: "Up",
	down: "Down",
	questionnaireNameRequired: "Questionnaire Name is required.",
	questionnaireDescriptionRequired: "Questionnaire Description is required.",
	trainingTypeRequired: "Training Type is required.",
	titleRequired: "title is required.",
	itemIsRequired: "item is required.",
	itemScoreRequired: "item score is required.",
	field: "textarea is required.",
	totalScoreTips: "There are currently {count} questions, and the maximum score is {totalScore} points",
	text: "Text",
	rating: "Rating",
	addQuestion: "Add Question",
	addOption: "Add Option",
	questionTotalScore: "Total Score",
	maxScoreRequired: "Please enter the maximum score you can get for this question",
	itemScore: "Option Score",
	ratingScoreValidate: "The minimum score for this question is 5 points",
	positiveInteger: "Please enter a positive integer",
	maxScoreValidate: "The question total score is required and must be 0 or positive integer",
	requiredPositiveInteger: "The score option is required and must be 0 or positive integer",
	radioItemOverScore: "The item score is over max score set.",
	maxScoreIs: "The max score of the option is not equal to the max score of the question set",
	itemOverScore: "The sum of option score is over max score set.",
	allMaxScoreOver: "The sum of the max score is more than 100",
	allMaxScoreLower: "The sum of the max score is less than 100",
	itemMaxScoreNotMaxScore: "The highest score for a single option is not equal to the highest score for the question",
	morItemMaxScoreLowerMaxScore: "The highest score of the combined options does not reach the highest score of the question",
	morItemMaxScoreOverMaxScore: "The highest score of multiple options combined exceeds the highest score of the question",
	deleteQuestionTips: "Are you sure you want to delete this question?",
	atLeastOne: "Add at least one question",
	confirmCancel: "Confirm cancel edit this page?",
	noQRcode: "This instructor did not generate a QR code",
	enterInstructorName: "Please enter the instructor name",
	instructorNameLimit: "Avoid entering special characters.",
	enterEmail: "Please enter the email",
	typeRequired: "Please enter the type",
	editInstructor: "Edit Instructor",
	totalScoreSubmitTips:
		"The total score of the current questionnaire is less than 100 points, please check the question settings.",
	totalScoreOverSubmitTips:
		"The total score of the current questionnaire is more than 100 points, please check the question settings."
};
